


































































































import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { defineComponent, ref, reactive } from '@vue/composition-api';
import DynamicReportCOJ from '@creditok/dynamic-report/src/DynamicReportCOJ.vue';
import commonMixin from '../mixins/common';

export default defineComponent({
  name: 'COJ',
  components: {
    DynamicReportCOJ,
  },
  setup(props, context) {
    const emptyState = {
      IconEmptyState: 'https://cdn.creditok.co/images/dynamic-form/report__coj--empty-state.svg',
      IconNoResult: 'https://cdn.creditok.co/images/dynamic-form/report__coj--no-result.svg',
      title: 'ค้นหาข้อมูลจากศาลล้มละลายกลาง',
      subtitle: 'โปรดระบุเลขประจำตัวประชาชนหรือเลขนิติบุคคลที่ต้องการค้นหา',
      TextNoResult: 'ไม่พบข้อมูลจากศาลล้มละลายกลางของบริษัทที่คุณค้นหา',
    };
    const common = commonMixin(context, {
      nid: '',
      jp_no: '',
    });
    common.apiPath.value = '/api/coj/';
    common.validate.value = ():boolean => {
      if (common.formData.nid && !(/[0-9]{13}/).test(common.formData.nid)) {
        common.errors.nid = 'Invalid national ID';
        return false;
      }
      if (common.formData.jp_no && !(/[0-9]{13}/).test(common.formData.jp_no)) {
        common.errors.jp_no = 'Invalid number';
        return false;
      }
      return true;
    };

    return {
      ...common,
      emptyState,
    };
  },
});
