





























































































































































import { defineComponent, reactive } from '@vue/composition-api';
import forEach from 'lodash/forEach';
import { extractKeyValuePairData, formatDate } from './utils';

export default defineComponent({
  name: 'DynamicReportCOJ',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = reactive(props.data.result);

    apiData.forEach(item => {
      item.partner_record.map((record, index): object => {
        Object.keys(record).forEach(field => {
          Object.assign(record, { [field]: record[field] || '-' });
        });

        return record;
      });

      item.coj_record.map((record, index): object => {
        const fields = Object.keys(record.data);

        // Object.keys(record).forEach(field => {
        //   Object.assign(record, { [field]: record.data[field] || '-' });
        // });
        Object.assign(record, {
          ...extractKeyValuePairData(fields, record.data),
        });

        return record;
      });
    });
    return {
      title: 'ข้อมูลศาลล้มละลายกลาง',
      apiData,
      formatDate,
    };
  },

});
