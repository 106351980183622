







































































import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { defineComponent, ref, reactive } from '@vue/composition-api';
import DynamicReportTaxReturn from '@creditok/dynamic-report/src/DynamicReportTaxReturn.vue';
import commonMixin from '../mixins/common';

export default defineComponent({
  name: 'TaxReturn',
  components: {
    DynamicReportTaxReturn,
  },
  setup(props, context) {
    const emptyState = {
      IconEmptyState: 'https://cdn.creditok.co/images/dynamic-form/report__dbd--empty-state.svg',
      IconNoResult: 'https://cdn.creditok.co/images/dynamic-form/report__dbd--no-result.svg',
      title: 'ค้นหาข้อมูลภาษีเงินคืน',
      subtitle: 'โปรดระบุหมายเลขนิติบุคคลของบริษัทที่คุณต้องการค้นหา',
      TextNoResult: 'ไม่พบข้อมูลภาษีเงินคืนของบริษัทที่คุณค้นหา',
    };
    const common = commonMixin(context, {
      nid: '',
    });
    common.apiPath.value = '/api/tax-return/';
    common.validate.value = ():boolean => {
      if (!common.formData.nid || !(/[0-9]{13}/).test(common.formData.nid)) {
        common.errors.nid = 'Invalid national ID';
        return false;
      }
      return true;
    };

    return {
      ...common,
      emptyState,
    };
  },
});
