







































































import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { defineComponent, ref, reactive } from '@vue/composition-api';
import DynamicReportBusinessInfo from '@creditok/dynamic-report/src/DynamicReportBusinessInfo.vue';
import commonMixin from '../mixins/common';

export default defineComponent({
  name: 'DBD',
  components: {
    DynamicReportBusinessInfo,
  },
  setup(props, context) {
    const emptyState = {
      IconEmptyState: 'https://cdn.creditok.co/images/dynamic-form/report__business-info--empty-state.svg',
      IconNoResult: 'https://cdn.creditok.co/images/dynamic-form/report__business-info--no-result.svg',
      title: 'ค้นหาข้อมูลธุรกิจของบริษัท',
      subtitle: 'โปรดระบุหมายเลขนิติบุคคลของบริษัทที่คุณต้องการค้นหา',
      TextNoResult: 'ไม่พบข้อมูลธุรกิจของบริษัทที่คุณค้นหา',
    };
    const common = commonMixin(context, {
      jp_no: '',
    });
    common.apiPath.value = '/api/dbd/';
    common.validate.value = ():boolean => {
      if (!common.formData.jp_no || !(/[0-9]{13}/).test(common.formData.jp_no)) {
        common.errors.jp_no = 'Invalid number';
        return false;
      }
      return true;
    };

    return {
      ...common,
      emptyState,
    };
  },
});
