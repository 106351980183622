






















import * as firebase from 'firebase/app';
import 'firebase/auth';
import { defineComponent, ref, watch } from '@vue/composition-api';
import useAuth from '../composables/use-auth';

export default defineComponent({

  setup(props, context) {
    const { isAuthed, login } = useAuth();
    const errorMsg = ref('');

    function loginWithProvider(e) {
      login().catch(err => {
        console.error(err);
        errorMsg.value = err.message;
      });
    }

    watch(isAuthed, (val, oldVal) => {
      if (val && !oldVal) {
        context.root.$router.replace({ name: 'home', params: context.root.$route.params });
      }
    });
    return {
      loginWithProvider,
      errorMsg,
    };
  },
});
