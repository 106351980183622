




















































































































import { defineComponent, reactive } from '@vue/composition-api';
import { formatInteger } from './utils';
import { $DYNAMIC_REPORT_CONFIGS } from './composables/config';

export default defineComponent({
  name: 'DynamicReportDBD',
  props: {
    data: {
      required: true,
      type: Object,
    },
    configs: {
      required: false,
      type: Array,
    },
  },
  setup(props) {
    const apiData = reactive(props.data);

    if (props.configs) {
      $DYNAMIC_REPORT_CONFIGS.value = props.configs;
    }

    return {
      apiData,
      formatInteger,
    };
  },
});
