export default [
  {
    name: 'balance_sheet',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'สินทรัพย์หมุนเวียน (Current Asset)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'cash',
        display: true,
        indent: 2,
        bold: false,
        italic: false,
      },
      {
        name: 'accounts_receivable',
        display: true,
        indent: 2,
        bold: false,
        italic: false,
      },
      {
        name: 'inventory',
        display: true,
        indent: 2,
        bold: false,
        italic: false,
      },
      {
        name: 'current_asset',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'สินทรัพย์ไม่หมุนเวียน (Non-current Asset)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'property_plant_equipment',
        display: true,
        indent: 2,
        bold: false,
        italic: false,
      },
      {
        name: 'non_current_asset',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'total_asset',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'หนี้สินหมุนเวียน (Current Liability)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'accounts_payable',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'other_current_liability',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'current_liability',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'หนี้สินหมุนเวียน (None-current Liability)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'long_term_loan',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'other_non_current_liability',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'non_current_liability',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'total_liability',
        label_th: 'รวมหนี้สิน',
        label_en: 'Total Liability',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'shareholder_equity',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'total_liability_equity',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
    ],
  },
  {
    name: 'income_statement',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'total_income',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'income',
        display: true,
        indent: 1,
        bold: false,
        italic: false,
      },
      {
        name: 'cost_of_sales',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'gross_profit',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'administrative_expense',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'total_expense',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'ebit',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'interest_expense',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'ebt',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'income_tax',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'net_profit',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
    ],
  },
  {
    name: 'profitability_ratio',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'อัตราส่วนความสามารถในการทํากําไร (Profitability Ratio)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'gross_profit_margin',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'operating_profit_margin',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'net_profit_margin',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
    ],
  },
  {
    name: 'return_ratio',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'อัตราส่วนผลตอบแทน (Return Ratio)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'return_on_assets',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'return_on_equity',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
    ],
  },
  {
    name: 'liquidity_ratio',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'อัตราส่วนสภาพคล่อง (Liquidity Ratio)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'current_ratio',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'quick_ratio',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'cash_ratio',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
    ],
  },
  {
    name: 'efficiency_ratio',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'อัตราส่วนประสิทธิภาพในการดำเนินงาน (Efficiency Ratio)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'total_asset_turnover',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'property_plant_equipment_turnover',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'accounts_receivable_turnover',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'ar_days',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'inventory_turnover',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'inventory_days',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'accounts_payable_turnover',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'ap_days',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'trade_cycle',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
    ],
  },
  {
    name: 'solvency_ratio',
    display: true,
    series: [
      {
        name: 'anual_report',
        number_of_year: 5,
        display: true,
      },
      {
        name: 'median',
        display: true,
      },
      {
        name: 'percentile',
        display: true,
      },
    ],
    fields: [
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
      {
        name: 'อัตราส่วนการวิเคราะห์ความอยู่รอด (Solvency Ratio)',
        is_insertion: true,
        type: 'section_header',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'debt_to_equity',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        name: 'interest_coverage',
        display: true,
        indent: 0,
        bold: false,
        italic: false,
      },
      {
        is_insertion: true,
        type: 'spacer',
        name: 'spacer',
        display: true,
      },
    ],
  },
];
