













































































































import { defineComponent, reactive } from '@vue/composition-api';

export default defineComponent({
  name: 'DynamicReportBusinessInfo',
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const apiData = reactive(props.data);

    return {
      apiData,
    };
  },
});
