













































































import {
  defineComponent, ref, reactive, computed,
} from '@vue/composition-api';
import useAuth from '../composables/use-auth';

export default defineComponent({
  setup(props, context) {
    const { user, customer, logout: _logout } = useAuth();
    const creditAvailable = computed(() => (customer.value ? customer.value.credit_available : 0));
    const navigationTabs = [
      { name: 'businessinfo', label: 'Business Info', icon: '' },
      { name: 'dbd', label: 'DBD', icon: '' },
      { name: 'coj', label: 'COJ', icon: '' },
      { name: 'gov', label: 'Government Project', icon: '' },
      { name: 'tax_return', label: 'Tax Return', icon: '' },
    ];
    const currentTab = ref(navigationTabs[0].name);

    function goTo(name) {
      context.root.$router.push({ name, params: context.root.$route.params });
      currentTab.value = name;
    }
    function logout() {
      _logout().then(() => {
        context.root.$router.push({ name: 'login', params: context.root.$route.params });
      });
    }
    return {
      user,
      customer,
      creditAvailable,
      navigationTabs,
      goTo,
      logout,
      currentTab,
    };
  },
});
