import axios from 'axios';
import set from 'lodash/set';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { ref, reactive, SetupContext } from '@vue/composition-api';
import { dispatch } from 'vuex-pathify';
import useAuth from '../composables/use-auth';

const { getUserToken, fetchCustomer } = useAuth();

export const cacheBank = ref({});

export default <T extends Object>(context:SetupContext, input:T) => {
  const formData = reactive({ ...input });
  const errors = reactive({ ...input });
  const isLoading = ref(false);
  const result = ref<Object>(null);
  const apiPath = ref('/');
  const isReqested = ref(false);

  const validate = ref(():boolean => {
    console.warn('No validation');
    return true;
  });

  async function search() {
    if (!validate.value()) {
      return;
    }
    try {
      isLoading.value = true;
      result.value = null;
      forEach(errors as Object, (value, key) => {
        errors[key] = '';
      });

      let data;
      const cacheKey = JSON.stringify(formData);
      const cache = get(cacheBank.value, [apiPath.value, cacheKey]);
      if (cache) {
        data = cache;
      } else {
        const res = await axios.get(apiPath.value, {
          headers: {
            Authorization: await getUserToken(),
          },
          params: formData,
        });
        data = res.data;
      }

      if (!isEmpty(data)) {
        result.value = data;
        if (!cache) {
          set(cacheBank.value, [apiPath.value, cacheKey], result.value);
        }
      } else {
        dispatch('app/addAlert', 'Not found');
      }
    } catch (e) {
      if (e.response.status === 403) {
        dispatch('app/addAlert', 'เครดิตของคุณมีไม่เพียงพอ');
      } else {
        dispatch('app/addAlert', `Request Error: ${e}`);
      }
    }
    await fetchCustomer();
    await context.root.$nextTick();
    isReqested.value = true;
    isLoading.value = false;
  }

  return {
    formData,
    errors,
    result,
    isLoading,
    apiPath,
    validate,
    search,
    cacheBank,
    isReqested,
  };
};
